import React, { useEffect, useState } from "react";
import { cacheBuster } from "./config";

function TeluguChannels() {
  const [tabs] = useState([
    "tv9telugu",
    "ntvtelugu",
    "abntelugu",
    "sakshitv",
    "v6news",
    "tv10telugu",
    "tnews",
    "etvandhrapradeshlive",
    "tv5telugu",
    "rajnewstelugu",
    "hmtvnews",
    "zeetelugunews",
  ]);

  const [selectedTeluguTab, setSelectedTeluguTab] = useState<string>(
    () => localStorage.getItem("selectedTeluguTab") || "tv9telugu"
  );

  const handleTabClick = (channelKey: string) => {
    setSelectedTeluguTab(channelKey);
    localStorage.setItem("selectedTeluguTab", channelKey);
  };

  useEffect(() => {
    const savedTab = localStorage.getItem("selectedTeluguTab");
    if (savedTab) {
      setSelectedTeluguTab(savedTab);
    }
  }, []);

  const [visibleTabs, setVisibleTabs] = useState(tabs);
  // Add channel name in "" which you want to hide with comma separated. For eg: const hiddenTabs: any = ["aajtak", "abpnews"];
  const hiddenTabs: any = ["tnews"];

  useEffect(() => {
    const updatedVisibleTabs = visibleTabs.filter(
      (tab) => !hiddenTabs.includes(tab)
    );
    setVisibleTabs(updatedVisibleTabs);

    if (hiddenTabs.includes(selectedTeluguTab)) {
      const nextVisibleTab = updatedVisibleTabs[0];
      if (nextVisibleTab) {
        setSelectedTeluguTab(nextVisibleTab);
      }
    }
  }, []);
  
  /*****  To add new logo add this line with new logo name start ******/
  const tv9telugu = `/assets/channel-logos/tv9-telugu.png?v=${cacheBuster}`;
  /*****  To add new logo add this line with new logo name end ******/
  const ntvtelugu = `/assets/channel-logos/ntv-telugu.jpeg?v=${cacheBuster}`;
  const abntelugu = `/assets/channel-logos/abn-telugu.jpeg?v=${cacheBuster}`;
  const sakshitv = `/assets/channel-logos/sakshi-tv.jpeg?v=${cacheBuster}`;
  const v6news = `/assets/channel-logos/v6-news.jpeg?v=${cacheBuster}`;
  const tv10telugu = `/assets/channel-logos/tv10-telugu.jpeg?v=${cacheBuster}`;
  const tnews = `/assets/channel-logos/tnews.jpeg?v=${cacheBuster}`;
  const etvandhrapradeshlive = `/assets/channel-logos/etv-andhra-pradesh-live.jpeg?v=${cacheBuster}`;
  const tv5telugu = `/assets/channel-logos/tv5-telugu.jpeg?v=${cacheBuster}`;
  const rajnewstelugu = `/assets/channel-logos/raj-news-telugu.jpeg?v=${cacheBuster}`;
  const hmtvnews = `/assets/channel-logos/hmtv-news.jpeg?v=${cacheBuster}`;
  const zeetelugunews = `/assets/channel-logos/zee-telugu-news.jpeg?v=${cacheBuster}`;

  const imagesToPreload = [
    tv9telugu,
    ntvtelugu,
    abntelugu,
    sakshitv,
    v6news,
    tv10telugu,
    tnews,
    etvandhrapradeshlive,
    tv5telugu,
    rajnewstelugu,
    hmtvnews,
    zeetelugunews,
  ];

  imagesToPreload.forEach((src) => {
    const img = new Image();
    img.src = src;
  });

  return (
    <>
      {/* Tabs for channel logos */}
      <div className="logo-grid">
        <div className="tabs">
          {/******  To add new logo copy paste this div(from line number 106 to 126) and change channel name wherever necessary and change the order start *******/}
          {visibleTabs.includes("tv9telugu") && (
            <div
              className={`tab-item ${
                selectedTeluguTab === "tv9telugu" ? "active" : ""
              } order-1`}
              onClick={() => handleTabClick("tv9telugu")}
            >
              {/**** When you add new logo change the src={tv9telugu} to src={newlogoname} in below div start ****/}
              <div className="image-container">
                <img
                  height="80"
                  width="80"
                  rel="preload"
                  src={tv9telugu}
                  alt="TV9 Telugu Live"
                />
              </div>
              {/**** When you add new logo change the src={indiatoday} to src={newlogoname} in below div end ****/}
            </div>
          )}
          {/******  To add new logo copy paste this div and change channel name wherever necessary and change the order end *******/}

          {visibleTabs.includes("ntvtelugu") && (
            <div
              className={`tab-item ${
                selectedTeluguTab === "ntvtelugu" ? "active" : ""
              } order-2`}
              onClick={() => handleTabClick("ntvtelugu")}
            >
              <div className="image-container">
                <img
                  height="80"
                  width="80"
                  rel="preload"
                  src={ntvtelugu}
                  alt="NTV Telugu Live"
                />
              </div>
            </div>
          )}

          {visibleTabs.includes("abntelugu") && (
            <div
              className={`tab-item ${
                selectedTeluguTab === "abntelugu" ? "active" : ""
              } order-3`}
              onClick={() => handleTabClick("abntelugu")}
            >
              <div className="image-container">
                <img
                  height="80"
                  width="80"
                  rel="preload"
                  src={abntelugu}
                  alt="ABN Telugu Live"
                />
              </div>
            </div>
          )}

          {visibleTabs.includes("sakshitv") && (
            <div
              className={`tab-item ${
                selectedTeluguTab === "sakshitv" ? "active" : ""
              } order-4`}
              onClick={() => handleTabClick("sakshitv")}
            >
              <div className="image-container">
                <img
                  height="80"
                  width="80"
                  rel="preload"
                  src={sakshitv}
                  alt="Sakshi TV Live"
                />
              </div>
            </div>
          )}

          {visibleTabs.includes("v6news") && (
            <div
              className={`tab-item ${
                selectedTeluguTab === "v6news" ? "active" : ""
              } order-5`}
              onClick={() => handleTabClick("v6news")}
            >
              <div className="image-container">
                <img
                  height="80"
                  width="80"
                  rel="preload"
                  src={v6news}
                  alt="V6 News Live"
                />
              </div>
            </div>
          )}

          {visibleTabs.includes("tv10telugu") && (
            <div
              className={`tab-item ${
                selectedTeluguTab === "tv10telugu" ? "active" : ""
              } order-6`}
              onClick={() => handleTabClick("tv10telugu")}
            >
              <div className="image-container">
                <img
                  rel="preload"
                  height="80"
                  width="80"
                  src={tv10telugu}
                  alt="10TV Telugu Live"
                />
              </div>
            </div>
          )}

          {visibleTabs.includes("tnews") && (
            <div
              className={`tab-item ${
                selectedTeluguTab === "tnews" ? "active" : ""
              } order-7`}
              onClick={() => handleTabClick("tnews")}
            >
              <div className="image-container">
                <img
                  height="80"
                  width="80"
                  rel="preload"
                  src={tnews}
                  alt="T News Live"
                />
              </div>
            </div>
          )}

          {visibleTabs.includes("etvandhrapradeshlive") && (
            <div
              className={`tab-item ${
                selectedTeluguTab === "etvandhrapradeshlive" ? "active" : ""
              } order-8`}
              onClick={() => handleTabClick("etvandhrapradeshlive")}
            >
              <div className="image-container">
                <img
                  height="80"
                  width="80"
                  rel="preload"
                  src={etvandhrapradeshlive}
                  alt="ETV Andhra Pradesh Live"
                />
              </div>
            </div>
          )}

          {visibleTabs.includes("tv5telugu") && (
            <div
              className={`tab-item ${
                selectedTeluguTab === "tv5telugu" ? "active" : ""
              } order-9`}
              onClick={() => handleTabClick("tv5telugu")}
            >
              <div className="image-container">
                <img
                  height="80"
                  width="80"
                  rel="preload"
                  src={tv5telugu}
                  alt="TV5 Telugu News Live"
                />
              </div>
            </div>
          )}

          {visibleTabs.includes("rajnewstelugu") && (
            <div
              className={`tab-item ${
                selectedTeluguTab === "rajnewstelugu" ? "active" : ""
              } order-10`}
              onClick={() => handleTabClick("rajnewstelugu")}
            >
              <div className="image-container">
                <img
                  height="80"
                  width="80"
                  rel="preload"
                  src={rajnewstelugu}
                  alt="Raj News Telugu Live"
                />
              </div>
            </div>
          )}

          {visibleTabs.includes("hmtvnews") && (
            <div
              className={`tab-item ${
                selectedTeluguTab === "hmtvnews" ? "active" : ""
              } order-11`}
              onClick={() => handleTabClick("hmtvnews")}
            >
              <div className="image-container">
                <img
                  height="80"
                  width="80"
                  rel="preload"
                  src={hmtvnews}
                  alt="Hmtv News Live"
                />
              </div>
            </div>
          )}

          {visibleTabs.includes("zeetelugunews") && (
            <div
              className={`tab-item ${
                selectedTeluguTab === "zeetelugunews" ? "active" : ""
              } order-12`}
              onClick={() => handleTabClick("zeetelugunews")}
            >
              <div className="image-container">
                <img
                  height="80"
                  width="80"
                  rel="preload"
                  src={zeetelugunews}
                  alt="ZEE Telugu News Live"
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Display the selected YouTube video */}

      <div className="video-container">
        {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary start  *******/}

        {selectedTeluguTab === "tv9telugu" &&
          visibleTabs.includes("tv9telugu") && (
            <>
              <h1>TV9 Telugu Live</h1>
              <iframe
                width="100%"
                height="450px"
                src={`https://www.youtube.com/embed/II_m28Bm-iM?si=FMymD7IMewL3U_az&autoplay=1&v=${cacheBuster}`}
                title="TV9 Telugu Live"
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </>
          )}

        {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary end *******/}

        {selectedTeluguTab === "ntvtelugu" &&
          visibleTabs.includes("ntvtelugu") && (
            <>
              <h1>NTV Telugu Live</h1>
              <iframe
                width="100%"
                height="450px"
                src={`https://www.youtube.com/embed/L0RktSIM980?si=HVjIViYO1Ov-1qKw&autoplay=1&v=${cacheBuster}`}
                title="NTV Telugu Live"
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </>
          )}

        {selectedTeluguTab === "abntelugu" &&
          visibleTabs.includes("abntelugu") && (
            <>
              <h1>ABN Telugu Live</h1>
              <iframe
                width="100%"
                height="450px"
                src={`https://www.youtube.com/embed/HoYsWagMFfE?si=GFWpyJEWHbS-0b5R&autoplay=1&v=${cacheBuster}`}
                title="ABN Telugu Live"
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </>
          )}

        {selectedTeluguTab === "sakshitv" &&
          visibleTabs.includes("sakshitv") && (
            <>
              <h1>Sakshi TV Live</h1>
              <iframe
                width="100%"
                height="450px"
                src={`https://www.youtube.com/embed/9kCcUipLPJM?si=a8YCbDUlMjzZ2a7_&autoplay=1&v=${cacheBuster}`}
                title="Sakshi TV Live"
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </>
          )}

        {selectedTeluguTab === "v6news" && visibleTabs.includes("v6news") && (
          <>
            <h1>V6 News Live</h1>
            <iframe
              width="100%"
              height="450px"
              src={`https://www.youtube.com/embed/_xtceKvlVYQ?si=Bm63JtfEASY-FH_1&autoplay=1&v=${cacheBuster}`}
              title="V6 News Live"
              loading="lazy"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </>
        )}

        {selectedTeluguTab === "tv10telugu" &&
          visibleTabs.includes("tv10telugu") && (
            <>
              <h1>10TV Telugu Live</h1>
              <iframe
                width="100%"
                height="450px"
                src={`https://www.youtube.com/embed/byG7EGw9NPs?si=FAH9p8UyHEBdiVGw&autoplay=1&v=${cacheBuster}`}
                title="10TV Telugu Live"
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </>
          )}

        {selectedTeluguTab === "tnews" && visibleTabs.includes("tnews") && (
          <>
            <h1>T News Live</h1>
            <iframe
              width="100%"
              height="450px"
              src={`https://www.youtube.com/embed/JnuYyOJ0XLU?si=Q_uRd_NRLBhOwuFs&autoplay=1&v=${cacheBuster}`}
              title="T News Live"
              loading="lazy"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </>
        )}

        {selectedTeluguTab === "etvandhrapradeshlive" &&
          visibleTabs.includes("etvandhrapradeshlive") && (
            <>
              <h1>ETV Andhra Pradesh Live</h1>
              <iframe
                width="100%"
                height="450px"
                src={`https://www.youtube.com/embed/KvZIEgHfscU?si=JMG_DjvFqYhIn_X0&autoplay=1&v=${cacheBuster}`}
                title="ETV Andhra Pradesh Live"
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </>
          )}

        {selectedTeluguTab === "tv5telugu" &&
          visibleTabs.includes("tv5telugu") && (
            <>
              <h1>TV5 Telugu News Live</h1>
              <iframe
                width="100%"
                height="450px"
                src={`https://www.youtube.com/embed/ho9JlBnrGWg?si=Bd_bgflwNL66EEUs&autoplay=1&v=${cacheBuster}`}
                title="TV5 Telugu News Live"
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </>
          )}

        {selectedTeluguTab === "rajnewstelugu" &&
          visibleTabs.includes("rajnewstelugu") && (
            <>
              <h1>Raj News Telugu Live</h1>
              <iframe
                width="100%"
                height="450px"
                src={`https://www.youtube.com/embed/HJDXSCINR3s?si=Wi04T1PbrEC9VpaT&autoplay=1&v=${cacheBuster}`}
                title="Raj News Telugu Live"
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </>
          )}

        {selectedTeluguTab === "hmtvnews" &&
          visibleTabs.includes("hmtvnews") && (
            <>
              <h1>Hmtv News Live</h1>
              <iframe
                width="100%"
                height="450px"
                src={`https://www.youtube.com/embed/3JodH4xoqJs?si=Ky6HTu1R-9PHIkTe&autoplay=1&v=${cacheBuster}`}
                title="Hmtv News Live"
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </>
          )}

        {selectedTeluguTab === "zeetelugunews" &&
          visibleTabs.includes("zeetelugunews") && (
            <>
              <h1>ZEE Telugu News Live</h1>
              <iframe
                width="100%"
                height="450px"
                src={`https://www.youtube.com/embed/ffI9-IioJK8?si=hYP6nK0CTHRthM9i&autoplay=1&v=${cacheBuster}`}
                title="ZEE Telugu News Live"
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </>
          )}
      </div>
    </>
  );
}

export default TeluguChannels;

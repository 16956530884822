import React, { useContext, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { DropdownContext } from "./DropdownContext";
import TeluguChannels from "./TeluguChannels";

function NewsChannels() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dropdownContext = useContext(DropdownContext);

  if (!dropdownContext) {
    throw new Error("DropdownContext must be used within DropdownProvider");
  }

  const { selectedOption } = dropdownContext;

  return (
    <>
      <div className="Apps">
        <Helmet>
          <title>Telugu News Live - Watch Telugu News Channels Online</title>
          <meta
            name="description"
            content="Stay updated with the latest news from Telugu-speaking regions. Watch live streaming of popular channels like TV9 Telugu, NTV, ABN Telugu, and more on Telugu News."
          />
          <meta
            name="keywords"
            content="Telugu news, live news, TV9 Telugu live, NTV Telugu live, ABN Telugu live, Sakshi TV live, V6 News live, 10TV live, T News live, ETV Andhra Pradesh live, TV5 Telugu live, Raj News Telugu live, HMTV live, ZEE Telugu live, Telugu news channels"
          />
        </Helmet>
        <Header />

        {selectedOption === "Telugu" && <TeluguChannels />}

      </div>
      <Footer />
    </>
  );
}

export default NewsChannels;

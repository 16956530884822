import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

function PrivacyPolicy() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="Apps">
        <Helmet>
          <title>
            Privacy Policy | Telugu News - Your Trusted Source for Telugu News
          </title>
          <meta
            name="description"
            content="Read the Privacy Policy of Telugu News to understand how we collect, use, and protect your personal information. Your privacy matters to us. Visit us now for live Telugu news updates!"
          />
          <meta
            name="keywords"
            content="Telugu news, live news, TV9 Telugu live, NTV Telugu live, ABN Telugu live, Sakshi TV live, V6 News live, 10TV live, T News live, ETV Andhra Pradesh live, TV5 Telugu live, Raj News Telugu live, HMTV live, ZEE Telugu live, Telugu news channel"
          />
        </Helmet>
        <Header />

        <div className="privacy-policy">
          <div className="container">
            <button className="back-arrow" onClick={goBack}>
              <i className="fa fa-long-arrow-left"></i>
            </button>
            <h1>Privacy Policy</h1>
          </div>

          <p>
            Welcome to Telugu News (www.telugu-news.in). Your privacy is
            important to us. This Privacy Policy outlines how we collect, use,
            disclose, and safeguard your information when you visit our website
            or use our services.
          </p>
          <ol className="bullet-list">
            <li>
              <h2>Information We Collect</h2>
              <p>
                When you visit Telugu News, we may collect information about you
                in various ways:
              </p>
              <ol className="dash">
                <li>
                  <h3>Personal Information</h3>: We may ask for personal information,
                  such as your name, email address, and phone number, when you
                  subscribe to our newsletter or contact us via our email
                  (contact@telugu-news.in).
                </li>
                <li>
                 <h3> Usage Data</h3>: We automatically collect information about your
                  device, including your IP address, browser type, operating
                  system, and the pages you visit on our site. This data helps
                  us analyze trends and improve user experience.
                </li>
                <li>
                  <h3>Cookies</h3>: Our website uses cookies to enhance your browsing
                  experience. Cookies are small data files stored on your device
                  that help us recognize you on future visits. You can manage
                  cookie preferences through your browser settings.
                </li>
              </ol>
            </li>

            <li>
            <h2>How We Use Your Information</h2>
              <p>
                We use the information we collect for the following purposes:
              </p>
              <ul className="dash-list">
                <li>
                  To Provide Services: We use your information to operate and
                  maintain our website, including providing you with access to
                  live Telugu news channels, such as [TV9 Telugu Live] (
                  <a
                    href="https://www.youtube.com/watch?v=II_m28Bm-iM"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.youtube.com/watch?v=II_m28Bm-iM
                  </a>
                  ), [NTV Telugu Live] (
                  <a
                    href="https://www.youtube.com/watch?v=L0RktSIM980"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.youtube.com/watch?v=L0RktSIM980
                  </a>
                  ), [ABN Telugu Live] (
                  <a
                    href="https://www.youtube.com/watch?v=HoYsWagMFfE"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.youtube.com/watch?v=HoYsWagMFfE
                  </a>
                  ), and more.
                </li>
                <li>
                  To Improve Our Website: We analyze usage data to enhance our
                  website’s functionality and user experience.
                </li>
                <li>
                  To Communicate with You: We may use your contact information
                  to respond to inquiries, send newsletters, and provide updates
                  about our services.
                </li>
              </ul>
            </li>
            <li>
            <h2>Information Sharing and Disclosure</h2>
              <p>
                We do not sell, trade, or otherwise transfer your personal
                information to outside parties. However, we may share your
                information in the following circumstances:
              </p>
              <ul className="dash-list">
                <li>
                  With Service Providers: We may engage third-party service
                  providers to assist us in operating our website or conducting
                  our business, who are obligated to keep your information
                  confidential.
                </li>
                <li>
                  For Legal Reasons: We may disclose your information if
                  required by law or in response to valid requests by public
                  authorities.
                </li>
              </ul>
            </li>
            <li>
            <h2>Security of Your Information</h2>
              <p>
                We implement reasonable security measures to protect your
                personal information from unauthorized access, use, or
                disclosure. However, no method of transmission over the internet
                or method of electronic storage is 100% secure. Therefore, we
                cannot guarantee its absolute security.
              </p>
            </li>
            <li>
            <h2>Links to Other Websites</h2>
              <p>
                Our website may contain links to third-party websites. We do not
                control these websites and are not responsible for their content
                or privacy practices. We encourage you to review the privacy
                policies of any third-party sites you visit.
              </p>
            </li>
            <li>
            <h2>Children's Privacy</h2>
              <p>
                Telugu News does not knowingly collect personal information from
                children under the age of 13. If you believe we have collected
                information from a child under this age, please contact us
                immediately, and we will take steps to delete such information.
              </p>
            </li>
            <li>
            <h2>Changes to This Privacy Policy</h2>
              <p>
                We may update our Privacy Policy from time to time. Any changes
                will be posted on this page with an updated effective date. We
                encourage you to review this Privacy Policy periodically for any
                changes.
              </p>
            </li>
            <li>
            <h2>Contact Us</h2>
              <p>
                If you have any questions or concerns about this Privacy Policy,
                please contact us at: Telugu News Email: [
                <a href="mailto:contact@telugu-news.in">
                  contact@telugu-news.in
                </a>
                ] Website: [
                <a
                  href="https://www.telugu-news.in"
                  target="_blank"
                  rel="noreferrer"
                >
                  www.telugu-news.in
                </a>
                ]
              </p>
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
